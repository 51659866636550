<!-- eslint-disable -->
<template>
    <header>
        <nav>
            <div class="logo">
                <img src="../../assets/imagenes/barra-fija/Logo Aika Cree.png" alt="Logo Aika Cree">    
            </div>
            <div class="icono" @click="toggleMenu">
                <span>&#9776;</span>
            </div>
            <div :class="['enlaces', isOpen ? 'dos' : 'uno']">
                <router-link to="/" @click="closeMenu">Inicio</router-link>
                <router-link to="/StoreAika" @click="closeMenu">Aika Store</router-link>
                <router-link to="/preguntas-frecuentes" @click="closeMenu">Preguntas Frecuentes</router-link>
                <a href="#footer" @click="closeMenu">Contáctanos</a>
                <a href="https://creditos.aikacree.com/" @click="closeMenu">Ingresa</a>  
            </div>
        </nav>
    </header>
</template>

<script lang="ts" src="./Navbar.ts"></script>

<style scoped>

/* Importar las fuentes directamente aquí */
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');


/*--------------Barra Fija-----------------*/
header nav {
  height: 50px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  padding: 0 20px;
  margin: 0;
  box-sizing: border-box;
}

.icono {
  display: none;
}

.enlaces {
  display: flex;
  height: 100%;
  width: 60%;
  justify-content: space-around;
  align-items: center;
}

.enlaces a {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  padding: 4px 0;
  transition: 0.7s ease-out;
  color: #9225c5;
  text-decoration: none;
  font-family: 'Bree Serif', serif;
  font-size: 25px;
}

.enlaces a:hover,
.active {
  border-bottom: 2px solid #9225c5;
}

/*-------------------logo barra fija-------------------*/
nav .logo {
  height: 100%;
  background: #fff;
  margin-left: 80px;
}

nav .logo img {
  object-fit: cover;
  height: inherit;
  max-width: 100%;
}

/* Estilos para pantallas medianas */
@media screen and (max-width: 750px) {
  .icono {
    display: flex;
    justify-content: center;
    height: 70px;
    align-items: center;
    color: #9225c5;
    background: transparent;
    padding: 20px;
    z-index: 100;
    cursor: pointer;
    /* margin-right: 10px; */
  }

  .enlaces {
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    width: 100%;
    flex-direction: column;
    transition: transform 0.3s ease;
    background: #9225c5;
    background: linear-gradient(to right, #9e68b8, #ffffff);
    transform: translateX(100%);
  }

  .enlaces.dos {
    transform: translateX(0);
  }

  .uno {
    transform: translateX(100%);
  }

  .enlaces a {
    font-size: 20px; /* Reducimos el tamaño de los enlaces */
    padding: 15px;
  }
}

/* Estilos para pantallas medianas, incluyendo iPad Mini */
@media screen and (max-width: 1400px) {
  .enlaces {
    width: 100%; /* Ocupar todo el ancho */
    justify-content: space-evenly; /* Distribuir los enlaces de manera uniforme */
    padding: 20px; /* Añadir padding para separar los enlaces del borde */
  }

  .enlaces a {
    font-size: 18px; /* Ajustar el tamaño de fuente */
    padding: 10px 20px; /* Aumentar el padding para más espacio entre los enlaces */
    margin: 0 10px; /* Añadir margen entre los enlaces */
  }

  nav .logo {
    margin-left: 20px;
  }

  .icono {
    margin-right: 20px;
  }
}



@media screen and (max-width: 1024px) {
  .enlaces {
    width: 100%; /* Ocupar todo el ancho */
    justify-content: space-evenly; /* Distribuir los enlaces de manera uniforme */
    padding: 20px; /* Añadir padding para separar los enlaces del borde */
  }

  .enlaces a {
    font-size: 18px; /* Ajustar el tamaño de fuente */
    padding: 10px 20px; /* Aumentar el padding para más espacio entre los enlaces */
    margin: 0 10px; /* Añadir margen entre los enlaces */
  }

  nav .logo {
    margin-left: 20px;
  }

  .icono {
    margin-right: 20px;
  }
}

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 400px) {
  nav .logo {
    margin-left: 20px;
  }

  .icono {
    margin-right: 20px;
  }

  .enlaces a {
    font-size: 1em;
    padding: 12px 5px;
  }
}

</style>