import { defineComponent } from 'vue';
import clock from '@/assets/imagenes/banner2/clock.png';
import dinerobillete from '@/assets/imagenes/banner2/dinerobillete.png';
import like from '@/assets/imagenes/banner2/like.png';
import simpeso from '@/assets/imagenes/banner2/simpeso.png';
import tv from '@/assets/imagenes/banner2/tv.png';

/* eslint-disable */
export default defineComponent({
    name: 'Banner',
    setup() {
        const items = [
            { src: dinerobillete, text: 'Sin necesidad de codeudor' },
            { src: tv, text: 'Aplica para compras de seguros, electrohogar y celulares' },
            { src: like, text: 'Cuotas fijas' },
            { src: simpeso, text: 'Sin experiencia crediticia' },
            { src: clock, text: 'Desembolso hasta en 24 horas' }
        ];

        return { items };
    }
});
