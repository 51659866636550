import { ref } from 'vue';
import router from '../../router';


export default {
    setup() {
        const isOpen = ref(false);

        const toggleMenu = () => {
            isOpen.value = !isOpen.value;
        };

        const closeMenu = () => {
            isOpen.value = false;
        };

        return {
            isOpen,
            toggleMenu,
            closeMenu,
            router
        };
    }
};