import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.slice(0, 3), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "banner-item",
          key: index
        }, [
          _createElementVNode("img", {
            src: item.src,
            alt: "Icono"
          }, null, 8, _hoisted_3),
          _createElementVNode("p", null, _toDisplayString(item.text), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.slice(3), (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "banner-item",
          key: index
        }, [
          _createElementVNode("img", {
            src: item.src,
            alt: "Icono"
          }, null, 8, _hoisted_5),
          _createElementVNode("p", null, _toDisplayString(item.text), 1)
        ]))
      }), 128))
    ])
  ]))
}