import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/imagenes/iconos/aika-cree-circulo.png'
import _imports_1 from '../../assets/imagenes/footer-principal/facebook.png'
import _imports_2 from '../../assets/imagenes/footer-principal/instagram.png'
import _imports_3 from '../../assets/imagenes/footer-principal/whatsapp.png'
import _imports_4 from '../../assets/imagenes/footer-principal/linked.png'


const _hoisted_1 = {
  class: "pie-pagina",
  id: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "grupo-1" }, [
      _createElementVNode("div", { class: "box" }, [
        _createElementVNode("figure", null, [
          _createElementVNode("a", { href: "#" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "logo derecha con fondo blanco"
            })
          ])
        ])
      ]),
      _createElementVNode("div", { class: "box" }, [
        _createElementVNode("form", {
          class: "footer__form",
          action: "https://formspree.io/f/mzbwnnww",
          method: "POST"
        }, [
          _createElementVNode("h2", { class: "footer__newsletter" }, "RECIBE NUESTRAS OFERTAS"),
          _createElementVNode("br"),
          _createElementVNode("div", { class: "footer__inputs" }, [
            _createElementVNode("input", {
              type: "email",
              placeholder: "Déjanos tu correo",
              class: "footer__input",
              name: "email"
            }),
            _createElementVNode("input", {
              type: "submit",
              value: "Enviar",
              class: "footer__submit"
            })
          ])
        ])
      ]),
      _createElementVNode("div", { class: "box" }, [
        _createElementVNode("div", { class: "red-social" }, [
          _createElementVNode("p", null, [
            _createTextVNode("Carrera 65a # 94a - 88 "),
            _createElementVNode("br"),
            _createTextVNode("Barrio Los Andes, Bogotá")
          ]),
          _createElementVNode("h2", null, "Síguenos"),
          _createElementVNode("a", { href: "https://www.facebook.com/profile.php?id=100090059415857&locale=es_LA" }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "footer__img"
            })
          ]),
          _createElementVNode("a", { href: "https://www.instagram.com/aikacree_libranzas/" }, [
            _createElementVNode("img", {
              src: _imports_2,
              class: "footer__img"
            })
          ]),
          _createElementVNode("a", {
            href: "https://api.whatsapp.com/send?phone=+573176684139&text=Hola, Deseo solicitar mi credito!",
            target: "_blank"
          }, [
            _createElementVNode("img", {
              src: _imports_3,
              class: "footer__img"
            })
          ]),
          _createElementVNode("a", { href: "https://linkedin.com/in/aikacree-libranzas-61445a310" }, [
            _createElementVNode("img", {
              src: _imports_4,
              class: "footer__img"
            })
          ])
        ])
      ])
    ], -1),
    _createElementVNode("div", { class: "grupo-2" }, [
      _createElementVNode("small", null, [
        _createTextVNode("© 2024 "),
        _createElementVNode("b", null, "AIKA CREE"),
        _createTextVNode(" - Todos los Derechos Reservados.")
      ])
    ], -1)
  ])))
}