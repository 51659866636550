<!-- eslint-disable -->
<template>
    <footer class="pie-pagina" id="footer">
        <div class="grupo-1">
            <div class="box">
                <figure>
                    <a href="#">
                        <img src="../../assets/imagenes/iconos/aika-cree-circulo.png" alt="logo derecha con fondo blanco" />
                    </a>
                </figure>
            </div>
            <div class="box">
                <form class="footer__form" action="https://formspree.io/f/mzbwnnww" method="POST">
                    <h2 class="footer__newsletter">RECIBE NUESTRAS OFERTAS</h2>
                    <br />
                    <div class="footer__inputs">
                        <input type="email" placeholder="Déjanos tu correo" class="footer__input" name="email" />
                        <input type="submit" value="Enviar" class="footer__submit" />
                    </div>
                </form>
            </div>
            <div class="box">
                <div class="red-social">
                    <p>Carrera 65a # 94a - 88 <br />Barrio Los Andes, Bogotá</p>
                    <h2>Síguenos</h2>
                    <a href="https://www.facebook.com/profile.php?id=100090059415857&locale=es_LA"><img src="../../assets/imagenes/footer-principal/facebook.png" class="footer__img" /></a>
                    <a href="https://www.instagram.com/aikacree_libranzas/"><img src="../../assets/imagenes/footer-principal/instagram.png" class="footer__img" /></a>
                    <a href="https://api.whatsapp.com/send?phone=+573176684139&text=Hola, Deseo solicitar mi credito!" target="_blank"><img src="../../assets/imagenes/footer-principal/whatsapp.png" class="footer__img" /></a>
                    <a href="https://linkedin.com/in/aikacree-libranzas-61445a310"><img src="../../assets/imagenes/footer-principal/linked.png" class="footer__img" /></a>
                  </div>
            </div>
        </div>
        <div class="grupo-2">
            <small>&copy; 2024 <b>AIKA CREE</b> - Todos los Derechos Reservados.</small>
        </div>
    </footer>
</template>

<script lang="ts" src="./Footer.ts"></script>

<style scoped>

@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2? family= Bree+Serif & family= Sofia+Sans+Condensed:wght@400;500;700 & display=swap");

/*--------------------footer-------------------------*/

.pie-pagina {
  width: 100%;
  background-color: #9225c5;
  padding: 0px 0;
}

.pie-pagina .grupo-1 {
  width: 100%;
  max-width: 1250px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  padding-top: 60px; /* Ajusta el padding superior */
  padding-bottom: 10px; /* Ajusta el padding inferior */
  justify-items: center;
  align-items: center;   /* Alinea verticalmente */
  box-sizing: border-box;
}

.pie-pagina .grupo-1 .box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0;
}

.pie-pagina .grupo-1 .box figure {
  margin: 0; /* Elimina el margen de la figura para centrar */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pie-pagina .grupo-1 .box figure img {
  width: 150px;
  max-width: 100%;
  height: auto;
}

.pie-pagina .grupo-1 .box h2 {
  color: #fff;
  margin-bottom: 5px;
  font-size: 20px;
}

.pie-pagina .grupo-1 .box p {
  color: #efefef;
  margin-bottom: 10px;
}

.pie-pagina .grupo-1 .red-social a {
  display: inline-block;
  text-decoration: none;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #fff;
  margin-right: 10px;
  background-color: #9225c5;
  text-align: center;
  transition: all 300ms ease;
}

.pie-pagina .grupo-1 .red-social a:hover {
  color: #9225c5;
}

.pie-pagina .grupo-2 {
  background-color: #fff;
  padding: 15px 10px;
  text-align: center;
  color: #9225c5;
  /* margin-bottom: 1%; */
  margin: 0; /* Elimina margen inferior */
}

.pie-pagina .grupo-2 small {
  font-size: 25px;
  margin: 0; /* Asegúrate de que no haya margen en este texto */
}

@media screen and (max-width: 800px) {
  .pie-pagina .grupo-1 {
    width: 90%;
    grid-template-columns: 1fr; /* Cambia a una columna en pantallas pequeñas */
    grid-gap: 30px;
    padding: 35px 0px;
  }

  .pie-pagina .grupo-1 .box {
    text-align: center; /* Asegura que el contenido quede centrado */
  }
}

footer {
  display: block;
  unicode-bidi: isolate;
  box-sizing: border-box;
}

/*----------boton enviar---------------*/
.footer__inputs {
  margin-top: 10px;
  display: flex;
  overflow: hidden;
}

.footer__input {
  background-color: #fff;
  height: 50px;
  display: block;
  padding-left: 10px;
  border-radius: 6px;
  font-size: 1rem;
  outline: none;
  border: none;
  margin-right: 16px;
}

.footer__submit {
  margin-left: auto;
  display: inline-block;
  height: 50px;
  padding: 0 20px;
  background-color: #2091f9;
  border: none;
  font-size: 1rem;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
}

.footer__copy {
  --padding-container: 30px 0;
  text-align: center;
  color: #fff;
}

.footer__copyright {
  font-weight: 300;
}

.footer__icons {
  margin-bottom: 10px;
}

.footer__img {
  width: 30px;
}

.footer__container {
  flex-wrap: wrap;
}

.nav--footer {
  width: 100%;
  justify-items: center;
}

.nav__link--footer {
  width: 100%;
  justify-content: space-evenly;
  margin: 0;
}

.footer__form {
  width: 100%;
  justify-content: space-evenly;
}

.footer__input {
  flex: 1;
}

</style>