import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slider-aliados" }
const _hoisted_2 = { class: "slider" }
const _hoisted_3 = { class: "slide-track" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "subtitle" }, "Nuestros Aliados:", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aliados, (aliado, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "slide",
            key: index
          }, [
            _createElementVNode("img", {
              src: aliado.src,
              alt: "Aliado"
            }, null, 8, _hoisted_4)
          ]))
        }), 128))
      ])
    ])
  ]))
}