import { defineComponent, ref, onMounted, onUnmounted  } from 'vue';
import Slider from '../Slider/Slider.vue';
import sliderTienda1 from '../../assets/imagenes/Store/Slider principal tienda/SLIDER_TIENDA1.png';
import sliderTienda2 from '../../assets/imagenes/Store/Slider principal tienda/SLIDER_TIENDA2.png';
import neveras from './Products/neveras';
import televisores from './Products/Televisores';
import lavadoras from './Products/Lavadoras';
import audio from './Products/Audio';
import estufas from './Products/Estufas';
import hornos from './Products/Hornos';
import celulares from './Products/Celulares';


export default defineComponent({
    name: 'StoreAika',
    components: {
        Slider
    },
    setup() {
        const slides = ref([
            { type: 'image', src: sliderTienda1, link: 'https://api.whatsapp.com/send?phone=+573176684139&text=Hola, Deseo solicitar mi credito para comprar este producto!' },
            { type: 'image', src: sliderTienda2 /* , link: 'https://ejemplo.com/1' */ }
        ]);

        const categories = ref([
            { name: 'Todos' },
            { name: 'Celulares' },
            { name: 'Televisores' },
            { name: 'Neveras' },
            { name: 'Lavadoras' },
            { name: 'Audio' },
            // { name: 'Audifonos' },
            // { name: 'Lavavajillas' },
            { name: 'Estufas' },
            { name: 'Hornos' },
            // { name: 'Licuadoras' }
        ]);

        const products = ref([
            ...celulares,
            ...televisores,
            ...neveras,
            ...lavadoras,
            ...audio,
            ...estufas,
            ...hornos,
        ]);

        const filteredProducts = ref(products.value);
        const hoveredProduct = ref<string | null>(null);
        const currentImageIndex = ref(0);

        // Variable de busqueda
        const searchQuery = ref('');
        // Categoría selecionada
        const selectedCategory = ref('Todos'); // Inicializa en 'Todos'
        
        const filterCategory = (category: string) => {
            selectedCategory.value = category;
            if (category === 'Todos') {
                filteredProducts.value = products.value;    
            } else {
                filteredProducts.value = products.value.filter(
                    (product) => product.category === category    
                );    
            }
            // Aplica el filtro de búsqueda después del filtrado por categoría
            applySearchFilter();
        };

        //Método para filtrar por productos.
        const applySearchFilter = () => {
            filteredProducts.value = products.value.filter((product) =>
                (selectedCategory.value === 'Todos' || product.category === selectedCategory.value) &&
                (!searchQuery.value || product.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
            );
        };

        // Método para calcular el precio con el 5% adicional
        const getPriceWithTax = (price: number) => {
            return price * 1.05; // calcula el 5% adicional
        };

        // Método para formatear el precio
        const formatPrice = (price: number) => {
            return new Intl.NumberFormat('es-CO', { 
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(price);
        };

        // Función para obtener un nombre corto
        const getDisplayedName = (name: string): string => {
            if (hoveredProduct.value === name) {
                return name; // Muestra el nombre completo si el mouse está sobre el elemento
            }
            const words = name.split(' ');
            return words.length > 2 ? words.slice(0, 2).join(' ') + '...' : name; // Dos primeras palabras
        };

        const startImageRotation = (productIndex: number) => {
            const product = filteredProducts.value[productIndex];
            if (product && !product.imageRotationInterval) {
                product.imageRotationInterval = window.setInterval(() => {
                    product.currentImageIndex = (product.currentImageIndex + 1) % product.image.length;
                }, 2000);
            }
        };
        
        const stopImageRotation = (productIndex: number) => {
            const product = filteredProducts.value[productIndex];
            if (product && product.imageRotationInterval) {
                clearInterval(product.imageRotationInterval);
                product.imageRotationInterval = null;
                product.currentImageIndex = 0; // Reset image index
            }
        };

        return {
            slides,
            categories,
            products,
            filteredProducts,
            filterCategory,
            getPriceWithTax,
            formatPrice,
            getDisplayedName,
            hoveredProduct,
            startImageRotation,
            stopImageRotation,
            currentImageIndex,
            searchQuery,
            applySearchFilter,
            selectedCategory
        };
    }
});