import { defineComponent, onMounted, ref } from 'vue';

/* eslint-disable */
export default defineComponent({
    name: 'Popup',
    setup() {
        // Usar referencias reactivas para el modal y el botón
        const modal = ref<HTMLElement | null>(null);
        const closeBtn = ref<HTMLElement | null>(null);
        const showIframe = ref(false); // Controla la visibilidad del iframe
        const iframeSrc = "https://www.youtube.com/embed/14lU4xHHmiw?autoplay=1&mute=1"; // URL del video

        onMounted(() => {
            // Obtener el modal y el botón cerrar
            modal.value = document.getElementById("myModal");
            closeBtn.value = document.querySelector(".close");

            // Mostrar el modal automáticamente al cargar la página
            if (modal.value) {
                modal.value.style.display = "flex"; // Usamos flex para centrar el modal
                showIframe.value = true; // Cargar el iframe cuando se muestre el modal
            }

            // Cuando el cliente hace click en la "X", se cierra el modal
            if (closeBtn.value) {
                closeBtn.value.onclick = () => {
                    if (modal.value) {
                        modal.value.style.display = "none";
                        showIframe.value = false; // Ocultar iframe cuando se cierra el modal
                    }
                };
            }

            // Cuando el cliente hace click fuera del modal, también se cierra.
            window.onclick = (event: MouseEvent) => {
                if (event.target === modal.value) {
                    if (modal.value) {
                        modal.value.style.display = "none";
                        showIframe.value = false; // Ocultar iframe cuando se cierra el modal
                    }
                }
            };
        });

        return {
            modal,
            closeBtn,
            showIframe,
            iframeSrc
        };
    },
});