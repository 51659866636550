// importaciones de imagenes productos
import cr121Minibar from '../../../assets/imagenes/Store/Productos/NEVERAS/CR121/Minibar-Challenger.png';
import cr121Minibar2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CR121/Minibar-Challenger2.png';
import ch100Congelador from '../../../assets/imagenes/Store/Productos/NEVERAS/CH-100/Congelador-Horizontal.png';
import ch100Congelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CH-100/Congelador-Horizontal2.png';
import ci145tmCongelador from '../../../assets/imagenes/Store/Productos/NEVERAS/CI-145TM/Congelador-Indurama.png';
import ci145tmCongelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CI-145TM/Congelador-Indurama2.png';
import alaska145b5Congelador from '../../../assets/imagenes/Store/Productos/NEVERAS/ALASKA145B5/Congelador-MABE.png';
import alaska145b5Congelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/ALASKA145B5/Congelador-MABE2.png';
import efcc10c3hqwCongelador from '../../../assets/imagenes/Store/Productos/NEVERAS/EFCC10C3HQW/Congelador-horizontale.png';
import efcc10c3hqwCongelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/EFCC10C3HQW/Congelador-horizontale2.png';
import rmu210nacg1Nevera from '../../../assets/imagenes/Store/Productos/NEVERAS/RMU210NACG1/Nevera-MABE.png';
import rmu210nacg1Nevera2 from '../../../assets/imagenes/Store/Productos/NEVERAS/RMU210NACG1/Nevera-MABE2.png';
import nev220cetir2Nevera from '../../../assets/imagenes/Store/Productos/NEVERAS/NEV220CETIR2/Nevera-HACEB.png';
import nev220cetir2Nevera2 from '../../../assets/imagenes/Store/Productos/NEVERAS/NEV220CETIR2/Nevera-HACEB2.png';
import rmu235nacg1Nevera2 from '../../../assets/imagenes/Store/Productos/NEVERAS/RMU235NACG1/Nevera-Convencional.png';
import rmu235nacg1Nevera22 from '../../../assets/imagenes/Store/Productos/NEVERAS/RMU235NACG1/Nevera-Convencional2.png';
import alaska200b5Congelador from '../../../assets/imagenes/Store/Productos/NEVERAS/ALASKA200B5/Congelador-Horizontalm.png';
import alaska200b5Congelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/ALASKA200B5/Congelador-Horizontalm2.png';
import cr239Nevera from '../../../assets/imagenes/Store/Productos/NEVERAS/CR239/Nevera-Lumina.png';
import cr239Nevera2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CR239/Nevera-Lumina2.png';
import ch332Congelador from '../../../assets/imagenes/Store/Productos/NEVERAS/CH-332/Congelador-CHALLENGER.png';
import ch332Congelador2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CH-332/Congelador-CHALLENGER2.png';
import cr256Nevera from '../../../assets/imagenes/Store/Productos/NEVERAS/CR256/Nevera-CHALLENGER.png';
import cr256Nevera2 from '../../../assets/imagenes/Store/Productos/NEVERAS/CR256/Nevera-CHALLENGER2.png';

// Define la interfaz para cada producto
interface Product {
  name: string;
  category: string;
  image: string[]; // Array de strings para las rutas de las imágenes
  price: number;
  link: string;
  currentImageIndex: number;
  imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const neveras: Product[] = ([
    {
        name: 'Minibar Challenger -121 Litros Brutos – CR 121 Titanium Negro',
        category: 'Neveras',
        image: [cr121Minibar, cr121Minibar2],
        price: 824000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Congelador Horizontal Challenger 97 Litros Brutos – CH 100',
        category: 'Neveras',
        image: [ch100Congelador, ch100Congelador2],
        price: 870000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Congelador Indurama 145 Lts Blanco',
        category: 'Neveras',
        image: [ci145tmCongelador, ci145tmCongelador2],
        price: 989000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Congelador MABE 145Lts – ALASKA145B5',
        category: 'Neveras',
        image: [alaska145b5Congelador, alaska145b5Congelador2],
        price: 1055000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Congelador horizontal Electrolux 100 Lts EFCC10C3HQW',
        category: 'Neveras',
        image: [efcc10c3hqwCongelador, efcc10c3hqwCongelador2],
        price: 1105000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Nevera MABE.. Convencional 211Lts Brutos Grafito – RMU210NACG1',
        category: 'Neveras',
        image: [rmu210nacg1Nevera, rmu210nacg1Nevera2],
        price: 1193000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Nevera HACEB 220 lts,convencional titanium-NEV220CETIR2',
        category: 'Neveras',
        image: [nev220cetir2Nevera, nev220cetir2Nevera2],
        price: 1215000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Nevera Convencional 235 Lts. Brutos Grafito Mabe – RMU235NACG1',
        category: 'Neveras',
        image: [rmu235nacg1Nevera2, rmu235nacg1Nevera22],
        price: 1230000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Congelador Horizontal 200 L Brutos Blanco Mabe – ALASKA200B5',
        category: 'Neveras',
        image: [alaska200b5Congelador, alaska200b5Congelador2],
        price: 1242000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Nevera Lúmina Frost 239 Litros Brutos – CR 239',
        category: 'Neveras',
        image: [cr239Nevera, cr239Nevera2],
        price: 1250000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Congelador CHALLENGER 230 Lts Brutos – CH 332',
        category: 'Neveras',
        image: [ch332Congelador, ch332Congelador2],
        price: 1285000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
    {
        name: 'Nevera CHALLENGER Frost 256Lts Brutos Lúmina – CR 256',
        category: 'Neveras',
        image: [cr256Nevera, cr256Nevera2],
        price: 1346000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null    
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default neveras;