<template>
    <div class="social">
        <ul>
            <li>
                <a href="https://www.facebook.com/profile.php?id=100090059415857&locale=es_LA" target="_blank" class="icon-facebook">
                    <img src="../../assets/imagenes/iconos/facebook.svg" alt="Facebook" />    
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/aikacree_libranzas/" target="_blank" class="icon-instagram">
                    <img src="../../assets/imagenes/iconos/instagram.svg" alt="Instagram" />    
                </a>
            </li>
            <li>
                <a href="https://api.whatsapp.com/send?phone=+573176684139&text=Hola, Deseo solicitar mi credito!" target="_blank" class="icon-whatsapp">
                    <img src="../../assets/imagenes/iconos/whatsApp.svg" alt="WhatsApp" />        
                </a>
            </li>
            <li>
                <a href="https://linkedin.com/in/aikacree-libranzas-61445a310" target="_blank" class="icon-linked">
                    <img src="../../assets/imagenes/iconos/linkedin.svg" alt="LinkedIn" />    
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped>
.social {
    position: fixed;
    left: 0;
    top: 500px;
    z-index: 2000;
    transform: rotate(-0deg); /* Diagonal */
}

.social ul {
    list-style: none;
}

.social ul li {
    margin-bottom: 10px;
}

.social ul li a img {
    width: 40px;
    height: 35px;
    border-radius: 100%;
    transition: transform 03s ease;
}

.social ul li a img:hover {
    transform: scale(1.1);
}

.social ul li a {
    display: inline-block;
    text-decoration: none;
}

/* Media query para pantallas pequeñas */
@media (max-width: 1024px) {
    .social {
        top: 400px; /* Baja un poco la barra en pantallas más pequeñas */
    }

    .social ul li a img {
        width: 35px; /* Reduce el tamaño de los íconos */
        height: 30px;
    }
}

@media (max-width: 768px) {
    .social {
        top: 300px; /* Ajusta la posición de la barra */
    }

    .social ul li a img {
        width: 30px; /* Reduce aún más el tamaño en pantallas pequeñas */
        height: 25px;
    }
}

@media (max-width: 375px) {
    .social {
        top: 250px; /* Ajusta la posición */
    }

    .social ul li a img {
        width: 25px; /* Reduce más el tamaño */
        height: 20px;
    }
}

</style>