import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!
  const _component_InfoSection = _resolveComponent("InfoSection")!
  const _component_SliderAliados = _resolveComponent("SliderAliados")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Slider),
    _createVNode(_component_InfoSection),
    _createVNode(_component_SliderAliados),
    _createVNode(_component_Banner),
    _createVNode(_component_Popup)
  ], 64))
}