<template>
    <!-- Slide -->
    <div>
        <Slider :slides="slides" />   
    </div>
    <!-- Store -->
    <div class="wrap">
        <h1 class="title">Nuestros Productos</h1>

        <!-- Campo de búsqueda -->
        <input 
            type="text"
            v-model="searchQuery"
            @input="applySearchFilter"
            placeholder="Buscar Producto..."
            class="search-input" 
        />

        <div class="store-wrapper">
            <div class="category_list">
                <div v-for="category in categories" :key="category.name">
                    <a href="#products-list" class="category_item" :category="category.name" @click="filterCategory(category.name)">{{ category.name }}</a>
                </div>
            </div>
            
            <section class="product-list">
                <div class="product-item" 
                     v-for="(product, index) in filteredProducts" 
                     :key="index" 
                     :category="product.category"
                     @mouseenter="startImageRotation(index)" 
                     @mouseleave="stopImageRotation(index)">
                    <div class="product-card">    
                        <img :src="product.image[product.currentImageIndex]" :alt="product.name" />
                        <div class="product-info">
                            <h2 class="product-name"
                                :title="product.name"
                                @mouseover="hoveredProduct = product.name"
                                @mouseleave="hoveredProduct = null">
                                {{ getDisplayedName(product.name) }}
                            </h2>
                            <h3>Precio: ${{ formatPrice(getPriceWithTax(product.price)) }}</h3> <!-- Muestra el precio formateado --><br>
                            <a :href="product.link" class="btn">Solicita tu crédito y obten este producto.</a>
                        </div>
                    </div>        
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts" src="./StoreAika.ts"></script>

<style scoped>
.title {
    font-size: 60px;
    margin-bottom: 60px;
    color: #9225c5;
}

.wrap {
    width: 90%;
    margin: auto;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 1);    
}

.store-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.category_list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-top: 20px;

}

.category_list a {
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    padding: 4px 0;
    transition: 0.7s ease-out;
    color: #9225c5;
    text-decoration: none;
    font-family: 'Bree Serif', serif;
    font-size: 25px;
}

.category_list a:hover,
.active {
  border-bottom: 2px solid #9225c5;
}

.category_item {
    padding: 4px 0;
    transition: 0.7s;
}

.category_item:hover {
    background-color: #fafbfc; /* Color de fondo al pasar el mouse */
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.product-item {
    margin: 10px;
    width: 250px;
    text-align: center;
    display: flex;
    flex-direction: column; /* Asegura que el contenido esté en columna */
    justify-content: space-between; /* Espaciado uniforme */
}

.product-card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
}

.product-card:hover {
    transform: scale(1.05);
}

.product-card img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.product-info {
    padding: 15px;
    flex-grow: 1;
}

.product-info h2 {
    font-size: 1.25em;
    color: #333;
    margin: 10px 0;
    overflow: hidden; /* Oculta el desbordamiento */
    text-overflow: ellipsis; /* Agrega '...' si hay un desbordamiento */
    display: -webkit-box; /* Necesario para aplicar el limitador de líneas */
    -webkit-box-orient: vertical; /* Orienta el contenido verticalmente */
    /* -webkit-line-clamp: 2; Limita a 2 líneas */
}

.product-info h3 {
    color: #9225c5;
    font-size: 1.1em;
    margin: 5px 0;
}

.btn {
    display: block;
    background-color: #6a1b9a;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #a55cb4;
}

/* Estilo para el campo de busqueda */
.search-input {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6);
}

/* Responsividad para pantallas pequeñas */

@media (max-width: 360px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 80%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 20px;
    }

}

@media (max-width: 412px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 80%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 5px;
    }

}

@media (max-width: 640px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 50%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 20px;
    }

}

@media (max-width: 768px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 50%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 20px;
    }

}

@media (max-width: 820px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 50%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 1024px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 50%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 50px;
    }

}

@media (max-width: 540px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 60%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 30px;
    }

}

@media (max-width: 360px) and (max-height: 640px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 90%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 375px) and (max-height: 667px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 90%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 412px) and (max-height: 732px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 90%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 414px) and (max-height: 896px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 80%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 430px) and (max-height: 932px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 80%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

@media (max-width: 360px) and (max-height: 740px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 80%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 20px;
    }

}

@media (max-width: 344px) and (max-height: 882px) {
    .category_list {
    flex-direction: column; /* Cambia la dirección a columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    text-align: left;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Color de fondo para el navbar */
    padding: 10%; /* Espaciado interno */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 0 20px rgba(146, 37, 197, 0.6); /* Efecto de resplandor */
    width: 100%; /* Ajusta el ancho para centrado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.category_list a {
        width: 100%; /* Ocupa todo el ancho en dispositivos pequeños */
        display: inline-block;
        padding: 5px 0; /* Espaciado interno para centrar el texto */
        text-align: left;
        padding-left: 40px;
    }

}

</style>