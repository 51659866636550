// Importaciones de Imagenes de Lavadores
import cw4707Lavadora from '../../../assets/imagenes/Store/Productos/LAVADORAS/CW-4707/Lavadora-doble.png';
import cw4707Lavadora2 from '../../../assets/imagenes/Store/Productos/LAVADORAS/CW-4707/Lavadora-doble2.png';
import wp8wmrabwpcolLavadora from '../../../assets/imagenes/Store/Productos/LAVADORAS/WP8WMR.ABWPCOL/Lavadora-Lg.png';
import wp8wmrabwpcolLavadora2 from '../../../assets/imagenes/Store/Productos/LAVADORAS/WP8WMR.ABWPCOL/Lavadora-Lg2.png';
import wscb1001pxp70Lavadora from '../../../assets/imagenes/Store/Productos/LAVADORAS/WSCB1001P-XP70/Lavadora-Hisense.png';
import wscb1001pxp70Lavadora2 from '../../../assets/imagenes/Store/Productos/LAVADORAS/WSCB1001P-XP70/Lavadora-Hisense2.png';
import wscb1501pxp100Lavadora from '../../../assets/imagenes/Store/Productos/LAVADORAS/WSCB1501P-XP100/Lavadora-Hisense15.png';
import lma9020wdgaboLavadora from '../../../assets/imagenes/Store/Productos/LAVADORAS/LMA9020WDGABO/Lavadora-Automatica.png';
import lma9020wdgaboLavadora2 from '../../../assets/imagenes/Store/Productos/LAVADORAS/LMA9020WDGABO/Lavadora-Automatica2.png';

// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const lavadoras: Product[] = ([
    {
        name: 'Lavadora doble tina semi automática carga superior de 7 Kg – CW 4707',
        category: 'Lavadoras',
        image: [cw4707Lavadora, cw4707Lavadora2],
        price: 624000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Lavadora Lg.... 8kg Semiautomatica.',
        category: 'Lavadoras',
        image: [wp8wmrabwpcolLavadora, wp8wmrabwpcolLavadora2],
        price: 645000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Lavadora Hisense 10kg Semiautomatica.',
        category: 'Lavadoras',
        image: [wscb1001pxp70Lavadora, wscb1001pxp70Lavadora2],
        price: 725000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Lavadora Hisense 15kg Semiautomatica.',
        category: 'Lavadoras',
        image: [wscb1501pxp100Lavadora],
        price: 984000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Lavadora Automática Propela 9 Kg Diamond Gray Mabe – LMA9020WDGAB0',
        category: 'Lavadoras',
        image: [lma9020wdgaboLavadora, lma9020wdgaboLavadora2],
        price: 1200000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default lavadoras;