import rnc5dcolllkSonido from '../../../assets/imagenes/Store/Productos/AUDIO/RNC5.DCOLLLK/Torre-de-sonido.png';
import rnc5dcolllkSonido2 from '../../../assets/imagenes/Store/Productos/AUDIO/RNC5.DCOLLLK/Torre-de-sonido2.png';
import rnc7dcolllkSonido from '../../../assets/imagenes/Store/Productos/AUDIO/RNC7.DCOLLLK/Torre-LG.png';
import rnc7dcolllkSonido2 from '../../../assets/imagenes/Store/Productos/AUDIO/RNC7.DCOLLLK/Torre-LG2.png';

// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const audio: Product[] = ([
    {
        name: 'Torre de-sonido LG XBOOM RNC5 | Karaoke Star | DJ App y DJ Pad | 500 Watts RMS | Super Bass Boost | Multi Bluetooth',
        category: 'Audio',
        image: [rnc5dcolllkSonido, rnc5dcolllkSonido2],
        price: 900000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Torre LG-sonido xboom 1000 watts-RNC7.DCOLLLK',
        category: 'Audio',
        image: [rnc7dcolllkSonido, rnc7dcolllkSonido2],
        price: 1059000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default audio;