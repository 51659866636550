import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = { class: "store-wrapper" }
const _hoisted_3 = { class: "category_list" }
const _hoisted_4 = ["category", "onClick"]
const _hoisted_5 = { class: "product-list" }
const _hoisted_6 = ["category", "onMouseenter", "onMouseleave"]
const _hoisted_7 = { class: "product-card" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "product-info" }
const _hoisted_10 = ["title", "onMouseover"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_Slider, { slides: _ctx.slides }, null, 8, ["slides"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "title" }, "Nuestros Productos", -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.applySearchFilter && _ctx.applySearchFilter(...args))),
        placeholder: "Buscar Producto...",
        class: "search-input"
      }, null, 544), [
        [_vModelText, _ctx.searchQuery]
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              key: category.name
            }, [
              _createElementVNode("a", {
                href: "#products-list",
                class: "category_item",
                category: category.name,
                onClick: ($event: any) => (_ctx.filterCategory(category.name))
              }, _toDisplayString(category.name), 9, _hoisted_4)
            ]))
          }), 128))
        ]),
        _createElementVNode("section", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredProducts, (product, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "product-item",
              key: index,
              category: product.category,
              onMouseenter: ($event: any) => (_ctx.startImageRotation(index)),
              onMouseleave: ($event: any) => (_ctx.stopImageRotation(index))
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("img", {
                  src: product.image[product.currentImageIndex],
                  alt: product.name
                }, null, 8, _hoisted_8),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h2", {
                    class: "product-name",
                    title: product.name,
                    onMouseover: ($event: any) => (_ctx.hoveredProduct = product.name),
                    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hoveredProduct = null))
                  }, _toDisplayString(_ctx.getDisplayedName(product.name)), 41, _hoisted_10),
                  _createElementVNode("h3", null, "Precio: $" + _toDisplayString(_ctx.formatPrice(_ctx.getPriceWithTax(product.price))), 1),
                  _cache[3] || (_cache[3] = _createTextVNode()),
                  _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("a", {
                    href: product.link,
                    class: "btn"
                  }, "Solicita tu crédito y obten este producto.", 8, _hoisted_11)
                ])
              ])
            ], 40, _hoisted_6))
          }), 128))
        ])
      ])
    ])
  ], 64))
}