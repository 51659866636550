import wm1807bHorno from '../../../assets/imagenes/Store/Productos/HORNOS/WM1807B/Microondas-Whirlpool.png';
import wm1807bHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/WM1807B/Microondas-Whirlpool2.png';
import he2495Horno from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2495/Horno-Challenger.png';
import he2495Horno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2495/Horno-Challenger2.png';
import hge20vnHorno from '../../../assets/imagenes/Store/Productos/HORNOS/HGE-20VN/Horno-De-Empotrar.png';
import hge20vnHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HGE-20VN/Horno-De-Empotrar2.png';
import hg2540acHorno from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2540-AC/Horno-Challenger54.png';
import hg2540acHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2540-AC/Horno-Challenger542.png';
import hg2555Horno from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2555/Horno-Challerger-Mixto.png';
import hg2555Horno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2555/Horno-Challerger-Mixto2.png';
import he2502aHorno from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2502-A/Horno-Challerger-Electrico.png';
import he2502aHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2502-A/Horno-Challerger-Electrico2.png';
import hg2560nHorno from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2560-N/Horno-Challerger-Mixto60.png';
import hg2560nHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HG-2560-N/Horno-Challerger-Mixto602.png';
import he2651Horno from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2651/Horno-Challenger-electrico.png';
import he2651Horno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HE-2651/Horno-Challenger-electrico2.png';
import hge24vgtltHorno from '../../../assets/imagenes/Store/Productos/HORNOS/HGE-24VGTL-T/Horno-De-Empotrar-Abba.png';
import hge24vgtltHorno2 from '../../../assets/imagenes/Store/Productos/HORNOS/HGE-24VGTL-T/Horno-De-Empotrar-Abba2.png';

// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const hornos: Product[] = ([
    {
        name: 'Microondas Whirlpool 0.7 Pc Negro',
        category: 'Hornos',
        image: [wm1807bHorno, wm1807bHorno2],
        price: 435000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challenger elect 220v medioformato-HE-2495',
        category: 'Hornos',
        image: [he2495Horno, he2495Horno2],
        price: 756000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno De-Empotrar Abba HGE 20 V N | Negro',
        category: 'Hornos',
        image: [hge20vnHorno, hge20vnHorno2],
        price: 821000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challenger 54×59.5 -HG-2540-AC',
        category: 'Hornos',
        image: [hg2540acHorno, hg2540acHorno2],
        price: 825000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challerger Mixto Acero Inox Negro 60cm',
        category: 'Hornos',
        image: [hg2555Horno, hg2555Horno2],
        price: 865000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challerger Electrico 60 Cm 120v Inox',
        category: 'Hornos',
        image: [he2502aHorno, he2502aHorno2],
        price: 885000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challerger Mixto 60cm Negro',
        category: 'Hornos',
        image: [hg2560nHorno, hg2560nHorno2],
        price: 1074000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno Challenger electrico 220v-HE-2651',
        category: 'Hornos',
        image: [he2651Horno, he2651Horno2],
        price: 1094000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Horno De-Empotrar Abba HGE 24 V GTL | Termostato | Acero Inoxidable Y Vidrio Reflectivo',
        category: 'Hornos',
        image: [hge24vgtltHorno, hge24vgtltHorno2],
        price: 1276000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default hornos;