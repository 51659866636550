<template>
    <section class="questions container">
        <h2 class="subtitle">Preguntas Frecuentes</h2>
        <p class="questions__paragraph"></p>

        <section class="questions__container">
            <article class="questions__padding" v-for="(faq, index) in faqs" :key="index">
                <div class="questions__answer" @click="toggleFaq(index)">
                    <h3 class="questions__title">
                        {{ faq.question }}
                        <span class="questions__arrow" :class="{ 'questions__arrow--rotate': faq.open }">
                            <img :src="iconochevron" class="questions__img" alt="Chevron Icono" />
                        </span>
                    </h3>
                    <p class="questions__show" :style="{ height: faq.open ? 'auto' : '0' }">
                        {{ faq.answer }}
                    </p>
                </div>
            </article>
        </section>
    </section>
</template>

<script lang="ts" src="./PreguntasFrecuentes.ts"></script>

<style scoped>
.subtitle {
    font-size: 28px;
    font-weight: bold;
    color: #9225c5;
    margin-top: 100px;
}

.questions {
    text-align: center;
}

.questions__container {
    display: grid;
    gap: 2em;
    padding-top: 50px;
    padding-bottom: 100px;
}

.questions__padding {
    padding: 0;
    transition: padding 0.3s;
    border: 1px solid #c8b9ce;
    border-radius: 6px;
}

.questions__answer {
    padding: 0 30px 0;
    overflow: hidden;
}

.questions__title {
    text-align: left;
    display: flex;
    font-size: 20px;
    padding: 30px 0 30px;
    cursor: pointer;
    color: #333;
    justify-content: space-between;
}

.questions__arrow {
    border-radius: 50%;
    background-color: #9225c5;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    margin-left: 10px;
    transition: transform 0.3s;
}

.questions__arrow--rotate {
    transform: rotate(180deg);
}

.questions__show {
    text-align: left;
    height: 0;
    transition: height 0.3s;
}

.questions__show[style*="auto"] {
    max-height: 500px; /* Ajusta el valor según la altura máxima esperada */
    padding-bottom: 30px;
}

.questions__img {
    display: block;
}
</style>