import sp6043Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-6043/Cubierta-CHALLENGER.png';
import sp6043Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-6043/Cubierta-CHALLENGER2.png';
import cg4013eaEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/CG-401-3EA/Estufa-de-Empotrar.png';
import cg4013eaEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/CG-401-3EA/Estufa-de-Empotrar2.png';
import sp5040Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-5040/Cubierta-Challerger.png';
import sp5040Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-5040/Cubierta-Challerger2.png';
import sp6040Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-6040/Estufa-de-empotrar59.png';
import sp6040Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/SP-6040/Estufa-de-empotrar592.png';
import at1015nEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/AT101-5-N/Estufa-Abba.png';
import at1015nEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/AT101-5-N/Estufa-Abba2.png';
import en6017Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/EN6017/Estufa-de-EmpotrarI.png';
import en6017Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/EN6017/Estufa-de-EmpotrarI2.png';
import ab2015plpEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/AB201-5-PL-P/Estufa-Abba4.png';
import ct6043cristalgnEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/CT60-43-CRISTAL-GN/Cubierta-Haceb.png';
import ct6043cristalgnEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/CT60-43-CRISTAL-GN/Cubierta-Haceb2.png';
import pm6046v0Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/PM6046V0/Cubierta-de-Empotrar-60.png';
import pm6046v0Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/PM6046V0/Cubierta-de-Empotrar-602.png';
import pm6042gv0Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/PM6042GV0/Cubierta-de-Empotrara.png';
import pm6042gv0Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/PM6042GV0/Cubierta-de-Empotrara2.png';
import sq6762eiEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/SQ-6762-EI/Cubierta-Challerger-Empotrar.png';
import sq6762eiEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/SQ-6762-EI/Cubierta-Challerger-Empotrar2.png';
import ab2015mEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/AB201-5-M/Estufa-de-Piso.png';
import ab2015mEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/AB201-5-M/Estufa-de-Piso2.png';
import ab2016mEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/AB201-6-M/Estufa-de-Piso-Abba.png';
import ab2016mEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/AB201-6-M/Estufa-de-Piso-Abba2.png';
import emc5150snx0Estufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/EMC5150SNX0/Estufa-de-Piso-a-Gas.png';
import emc5150snx0Estufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/EMC5150SNX0/Estufa-de-Piso-a-Gas2.png';
import estorg60neEstufa from '../../../assets/imagenes/Store/Productos/ESTUFAS/ESTORG60-NE/Estufa-Oregano.png';
import estorg60neEstufa2 from '../../../assets/imagenes/Store/Productos/ESTUFAS/ESTORG60-NE/Estufa-Oregano2.png';


// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const estufas: Product[] = ([
    {
        name: 'Cubierta CHALLENGER 59cms 4 Puestos Gas Natural Plateado',
        category: 'Estufas',
        image: [sp6043Estufa, sp6043Estufa2],
        price: 398000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-Empotrar Abba Gas Natural CG-401-3EA Acero inoxidable',
        category: 'Estufas',
        image: [cg4013eaEstufa, cg4013eaEstufa2],
        price: 442000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Cubierta Challerger Empotrar 58.5cm Inox',
        category: 'Estufas',
        image: [sp5040Estufa, sp5040Estufa2],
        price: 458000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-empotrar en acero inoxidable de cuatro puestos de 59cm gas natural',
        category: 'Estufas',
        image: [sp6040Estufa, sp6040Estufa2],
        price: 587000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa AbbA-4pts Con Horno Negra',
        category: 'Estufas',
        image: [at1015nEstufa, at1015nEstufa2],
        price: 646000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-Empotrar Indurama Dual Natural / Propano EN6017 Inox',
        category: 'Estufas',
        image: [en6017Estufa, en6017Estufa2],
        price: 669000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa Abba-4pts Ee Torre Propano',
        category: 'Estufas',
        image: [ab2015plpEstufa],
        price: 867000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Cubierta Haceb Cub Tandoori Cristal 60-43 Gn',
        category: 'Estufas',
        image: [ct6043cristalgnEstufa, ct6043cristalgnEstufa2],
        price: 871000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Cubierta de-Empotrar 60 cm Vidrio Templado Negra Mabe – PM6046V0',
        category: 'Estufas',
        image: [pm6046v0Estufa, pm6046v0Estufa2],
        price: 880000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Cubierta de-Empotrar a Gas  60 cm Vidrio Negra Mabe – PM6042GV0',
        category: 'Estufas',
        image: [pm6042gv0Estufa, pm6042gv0Estufa2],
        price: 937000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Cubierta Challerger Empotrar 60 Cm Vidrio Negro',
        category: 'Estufas',
        image: [sq6762eiEstufa, sq6762eiEstufa2],
        price: 962000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-Piso.. Abba Gas Natural AB201-5-M Platino',
        category: 'Estufas',
        image: [ab2015mEstufa, ab2015mEstufa2],
        price: 1066000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-Piso. Abba Gas Natural AB201-6-M Platino',
        category: 'Estufas',
        image: [ab2016mEstufa, ab2016mEstufa2],
        price: 1153000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa de-Piso. a Gas de 52 cm Inoxidable Mabe – EMC5150SNX0',
        category: 'Estufas',
        image: [emc5150snx0Estufa, emc5150snx0Estufa2],
        price: 1258000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Estufa Orégano Haceb 60 cms negra-inox gas natural',
        category: 'Estufas',
        image: [estorg60neEstufa, estorg60neEstufa2],
        price: 1288000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default estufas;