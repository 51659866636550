<!-- eslint-disable -->
<template>
     <div id="myModal" class="modal">
          <div class="modal-content">
               <span class="close">&times;</span>
               <h1>Bienvenido a AikaCree.</h1>
               <p>¿Quieres solicitar tu crédito? Te enseñamos como !!!.</p>
               <div class="video-container">
                    <!-- Utiliza v-if para cargar el iframe solo cuando se muestre el modal -->
                    <iframe 
                        v-if="showIframe" 
                        :src="iframeSrc" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
               </div>
          </div>
     </div>       
</template>

<script lang="ts" src="./Popup.ts"></script>

<style scoped>
.modal {
     display: none; /* Oculto por defecto */
     position: fixed;
     /* Para que esté sobre el resto del contenido */
     z-index: 1000; 
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.8); /* Fondo Oscuro */
     justify-content: center;
     align-items: center;
}

.modal-content {
     background-color: #fff;
     padding: 20px;
     position: relative;
     border-radius: 8px;
     max-width: 80%;
     max-height: 80%;
     overflow: hidden;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);     
}

.close {
     position: absolute;
     top: 10px;
     right: 20px;
     font-size: 30px;
     font-weight: bold;
     color: #333;
     cursor: pointer;
}

.close:hover,
.close:focus {
     color: black;
     text-decoration: none;
     cursor: pointer;
}

.video-container {
     position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
     width: 100%;
}

.video-container iframe {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     border: none;
     border-radius: 8px;
}

.modal-content h1 {
     color: #9225c5;
     padding-bottom: 20px;
}

.modal-content p {
     padding-bottom: 20px;
}

</style>