// Importaciones de Imagenes de Televisores
import t32l87Televisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/32L87/Televisor-CHALLENGER.png';
import t32l87Televisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/32L87/Televisor-CHALLENGER2.png';
import t32tg79Televisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/32TG79/Televisor-Challenger32.png';
import t32tg79Televisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/32TG79/Televisor-Challenger322.png';
import m24tl520vpdawcMonitor from '../../../assets/imagenes/Store/Productos/TELEVISORES/24TL520V-PD.AWC/Monitor-LG.png';
import m24tl520vpdawcMonitor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/24TL520V-PD.AWC/Monitor-LG2.png';
import t40tg79Televisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/40TG79/Televisor-Challenger40.png';
import t40tg79Televisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/40TG79/Televisor-Challenger402.png';
import hyled4023gimTelevisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/HYLED4023GIM/Televisor-Hyundai.png';
import hyled4023gimTelevisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/HYLED4023GIM/Televisor-Hyundai2.png';
import t43tg79Televisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/43TG79/Televisor-Challenger43.png';
import t43tg79Televisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/43TG79/Televisor-Challenger432.png';
import hyled4322gimTelevisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/HYLED4322GIM/Televisor-HYUNDAI43.png';
import hyled4322gimTelevisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/HYLED4322GIM/Televisor-HYUNDAI432.png';
import t43a4kTelevisor from '../../../assets/imagenes/Store/Productos/TELEVISORES/43A4K/Televisor-Hisense.png';
import t43a4kTelevisor2 from '../../../assets/imagenes/Store/Productos/TELEVISORES/43A4K/Televisor-Hisense2.png';


// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const televisores: Product[] = ([
    {
        name: 'Televisor CHALLENGER 32” LED TV -LED 32L87',
        category: 'Televisores',
        image: [t32l87Televisor, t32l87Televisor2],
        price: 567000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor Challenger 32 Hd Google Tv T2',
        category: 'Televisores',
        image: [t32tg79Televisor, t32tg79Televisor2],
        price: 661000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Monitor LG........ 23,6″ Full HD- 24TL520V-PD.AWC',
        category: 'Televisores',
        image: [m24tl520vpdawcMonitor, m24tl520vpdawcMonitor2],
        price: 847000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor Challenger 40 Full Hd Google Tv',
        category: 'Televisores',
        image: [t40tg79Televisor, t40tg79Televisor2],
        price: 881000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor Hyundai 40 Led Digital Smart',
        category: 'Televisores',
        image: [hyled4023gimTelevisor, hyled4023gimTelevisor2],
        price: 901000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor Challenger 43 Full Hd Google Tv',
        category: 'Televisores',
        image: [t43tg79Televisor, t43tg79Televisor2],
        price: 966000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor HYUNDAI 43″ Smart tv Google, FHD, Comando de voz -HYLED4322GIM',
        category: 'Televisores',
        image: [hyled4322gimTelevisor, hyled4322gimTelevisor2],
        price: 994000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Televisor Hisense 43 Led Fhd Smart Tv',
        category: 'Televisores',
        image: [t43a4kTelevisor, t43a4kTelevisor2],
        price: 1095000,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    }
]);

// Exporta el array para utilizarlo en otros archivos
export default televisores;