import redminote13Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13/Celular-XiaomiIceBlue.png';
import redminote13Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13/Celular-XiaomiBlack.png';
import redminote13proCelular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-PRO/Celular-Xiaomi-RedmiGreen.png';
import redminote13proCelular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-PRO/Celular-Xiaomi-RedmiViolet.png';
import redminote13proCelular3 from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-NOTE-13-PRO/Celular-Xiaomi-RedmiBlack.png';
import redmi13Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REDMI-13/Celular-Xiaomi-Redmi13.png';
import realmenote50Celular from '../../../assets/imagenes/Store/Productos/CELULARES/REALME-NOTE-50/Celular-Realme-Note-50.png';
import realmenote50Celular2 from '../../../assets/imagenes/Store/Productos/CELULARES/REALME-NOTE-50/Celular-Realme-Note-502.png';

// Define la interfaz para cada producto
interface Product {
    name: string;
    category: string;
    image: string[]; // Array de strings para las rutas de las imágenes
    price: number;
    link: string;
    currentImageIndex: number;
    imageRotationInterval: number | null;
}

// Exporta el array de productos como constante
const celulares: Product[] = ([
    {
        name: 'Celular REALME NOTE 50 de 64gb, Negro',
        category: 'Celulares',
        image: [realmenote50Celular, realmenote50Celular2],
        price: 329900,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI NOTE 13 8GB + 256GB, Negro, Ice Blue',
        category: 'Celulares',
        image: [redminote13Celular, redminote13Celular2],
        price: 647360,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI NOTE 13 PRO 8GB + 256GB VERDE, VIOLETA, NEGRO',
        category: 'Celulares',
        image: [redminote13proCelular, redminote13proCelular2, redminote13proCelular3],
        price: 933287,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
    {
        name: 'Celular Xiaomi REDMI 13 NEGRO 8GB + 256GB',
        category: 'Celulares',
        image: [redmi13Celular],
        price: 673500,
        link: 'https://creditos.aikacree.com/',
        currentImageIndex: 0,
        imageRotationInterval: null as number | null
            
    },
]);

// Exporta el array para utilizarlo en otros archivos
export default celulares;