import { defineComponent, reactive } from 'vue';
import iconochevron from '../../assets/imagenes/iconos/chevron-abajo-en-círculo.png'

export default defineComponent({
    name: 'PreguntasFrecuentes',
    setup() {
        const faqs = reactive([
            {
                question: '¿Qué condiciones debo reunir para solicitar un crédito?',
                answer: 'La empresa donde laboras debe tener un convenio vigente con Aika Cree, también puedes contactarnos con tu empleador y nosotros gestionamos el convenio.',
                open: false,
            },
            {
                question: '¿Puedo solicitar un préstamo si estoy reportando?',
                answer: 'Sí, Aika aprueba tu crédito de acuerdo a tu perfil laboral, sin importar los reportes.',
                open: false,
            },
            {
                question: '¿Cuánto tiempo se demora el desembolso de mi crédito una vez aprobado?',
                answer: 'Después de aprobado el crédito, tarda 48 horas para su desembolso.',
                open: false,
            },
        ]);
        
        const toggleFaq = (index: number) => {
            faqs[index].open = !faqs[index].open;
        };

        return {
            faqs,
            toggleFaq,
            iconochevron
        };
    },
});