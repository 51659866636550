import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import image1 from '@/assets/imagenes/principal/1.png';
import image2 from '@/assets/imagenes/principal/2.png';
import image3 from '@/assets/imagenes/principal/3.png';
import videoNavidad from '@/assets/imagenes/principal/Video-navidad.mp4';

// Interfaz para tipar cada slide
interface Slide {
    type: 'image' | 'video';
    src: string;
    link?: string;
}

export default defineComponent({
    // Este Props es para pooderlo usar en StoreAika
    props: {
        slides: {
            type: Array as () => Slide[],
            required: true,
            default: () => [
                { type: 'video', src: videoNavidad, link: 'https://creditos.aikacree.com/' },
                { type: 'image', src: image1, link: '/StoreAika' },
                { type: 'image', src: image2, link: 'https://api.whatsapp.com/send?phone=+573176684139&text=Hola, Deseo solicitar mi credito!' },
                { type: 'image', src: image3, link: 'https://creditos.aikacree.com/' },
                { type: 'video', src: 'https://www.youtube.com/embed/14lU4xHHmiw?autoplay=1&mute=0', link: 'https://www.youtube.com/embed/14lU4xHHmiw' },
            ]
        }
    },
    setup(props) {
        const currentSlide = ref(0);
        let slideInterval: number | null = null;

        const startSlideShow = () => {
            if (slideInterval) clearInterval(slideInterval);
            slideInterval = setInterval(() => changeSlide(1), 20000);
        };

        const changeSlide = (n: number) => {
            if (slideInterval) clearInterval(slideInterval);
            currentSlide.value = (currentSlide.value + n + props.slides.length) % props.slides.length;
        
            const current = props.slides[currentSlide.value];
            startSlideShow();
        };
        
        const setSlide = (index: number) => {
            if (slideInterval) clearInterval(slideInterval);
            currentSlide.value = index;
            startSlideShow();
        };

        // Función que se ejecuta cuando se hace clic en el overlay del video
        const handleVideoClick = (link: string) => { 
            // Abre el enlace del video en una nueva pestaña
            window.open(link, '_blank');
        };

        onMounted(() => {
            startSlideShow();    
        });

        onUnmounted(() => {
            if (slideInterval) clearInterval(slideInterval);
        });

        
        return {
            currentSlide,
            changeSlide,
            setSlide,
            handleVideoClick
        };
    }   
});