import { defineComponent } from 'vue';
import aikahumana from '@/assets/imagenes/slider-convenios/aika-humana.png';
import centuriones from '@/assets/imagenes/slider-convenios/Centuriones.png';
import concremack from '@/assets/imagenes/slider-convenios/concremack.png';
import embriovet from '@/assets/imagenes/slider-convenios/embriovet.png';
import expresobolivariano from '@/assets/imagenes/slider-convenios/expreso-bolivariano.png';
import global from '@/assets/imagenes/slider-convenios/global.png';
import hym from '@/assets/imagenes/slider-convenios/hym.png';
import perforaciones from '@/assets/imagenes/slider-convenios/perforaciones-e-ingenieria.png';
import profesionales from '@/assets/imagenes/slider-convenios/profesionales-asociados.png';

export default defineComponent({
    name: 'SliderAliados',
    setup() {
        const aliados = [
            { src: aikahumana },
            { src: centuriones },
            { src: concremack },
            { src: embriovet },
            { src: expresobolivariano },
            { src: global },
            { src: hym },
            { src: perforaciones },
            { src: profesionales },
            // Se duplica para generar la vision de infito en el slider
            { src: aikahumana },
            { src: centuriones },
            { src: concremack },
            { src: embriovet },
            { src: expresobolivariano },
            { src: global },
            { src: hym },
            { src: perforaciones },
            { src: profesionales },
        ];

        return { aliados };
    },
});