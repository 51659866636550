import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "questions container" }
const _hoisted_2 = { class: "questions__container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "questions__title" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "subtitle" }, "Preguntas Frecuentes", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "questions__paragraph" }, null, -1)),
    _createElementVNode("section", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq, index) => {
        return (_openBlock(), _createElementBlock("article", {
          class: "questions__padding",
          key: index
        }, [
          _createElementVNode("div", {
            class: "questions__answer",
            onClick: ($event: any) => (_ctx.toggleFaq(index))
          }, [
            _createElementVNode("h3", _hoisted_4, [
              _createTextVNode(_toDisplayString(faq.question) + " ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(["questions__arrow", { 'questions__arrow--rotate': faq.open }])
              }, [
                _createElementVNode("img", {
                  src: _ctx.iconochevron,
                  class: "questions__img",
                  alt: "Chevron Icono"
                }, null, 8, _hoisted_5)
              ], 2)
            ]),
            _createElementVNode("p", {
              class: "questions__show",
              style: _normalizeStyle({ height: faq.open ? 'auto' : '0' })
            }, _toDisplayString(faq.answer), 5)
          ], 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}