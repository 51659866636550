<template>
    <div class="container-all">
        <h1 class="title">Bienvenido a AIKACREE</h1>
        <img src="@/assets/imagenes/banner/grafico.png" alt="Gráfico descriptivo" />
        <h1 class="sub-title">¿Cómo funcionamos?</h1>
        <p>
            Somos un Operador de Libranzas, un crédito que otorgamos a empleados por
            medio de un convenio que se firma con la empresa y se descuenta en cómodas
            cuotas de tu nómina.
            <br>
            <br>
            Tú como empleado autorizas al empleador para descontar de tu salario las
            cuotas del crédito que AIKACREE te otorga.
        </p>
    </div>
</template>

<script lang="ts" src="./InfoSection.ts"></script>

<style scoped>
.container-all {
    width: 100%;
    max-width: 1899px;
    min-width: 350px;
    margin: 0 auto;
    padding: 10px;
    background: rgb(255, 255, 255);
    overflow: hidden;    
}

.container-all img {
    width: 500px;
    float: left;
    margin-right: 40px;
    margin-bottom: 20px;
    align-items: center;
}

.container-all p {
    font-size: 24px; /* Ajuste para un tamaño más equilibrado */
    font-weight: 500; /* Reducimos un poco el grosor para mejorar la legibilidad */
    line-height: 1.8; /* Aumentamos el espacio entre líneas */
    text-align: justify; /* Justificamos el texto para un aspecto más formal */
    margin: 40px 15% 30px 30%; /* Cambiamos el margen izquierdo a 20% */
    color: #4a4a4a; /* Un tono gris oscuro más suave que el negro puro */
    padding: 10px;
    border-left: 5px solid #9225c5; /* Agregamos un borde llamativo para resaltar el texto */
    background-color: #f9f9f9; /* Un fondo claro para hacer que el texto resalte */
    border-radius: 8px; /* Bordes redondeados para un efecto más moderno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Un sutil efecto de sombra para darle profundidad */
    max-width: 1000px; /* Limitamos el ancho para mejorar la lectura en pantallas grandes */
}

.title {
    font-size: 60px;
    margin-bottom: 60px;
    color: #9225c5;
}

.sub-title {
    font-size: 60px;
    font-weight: 1400;
    text-align: center;
    color: #9225c5;
    margin-right: 10%;
}

/* Responsive para dispositivos medianos */
@media (max-width: 1280px) {
    .container-all img {
        width: 100%;
        float: none;
        margin: 0 auto 20px;
        display: block;
    }

    .container-all p {
        font-size: 22px;
        margin: 15px 1% 15px 10%; /* Cambiamos el margen izquierdo a 5% */
    }

    .title, .sub-title {
        font-size: 50px;
        margin-bottom: 30px;
    }
}

@media (max-width: 1024px) {
    .container-all img {
        width: 100%;
        float: none;
        margin: 0 auto 20px;
        display: block;
    }

    .container-all p {
        font-size: 24px;
        margin: 10px 5% 10px 5%; /* Cambiamos el margen izquierdo a 5% */
    }

    .title, .sub-title {
        font-size: 50px;
        margin-bottom: 30px;
    }
}

/* Responsive para dispositivos pequeños */
@media (max-width: 768px) {
    .container-all img {
        width: 100%;
        float: none;
        margin: 0 auto 20px;
        display: block;
    }

    .container-all p {
        font-size: 20px;
        text-align: justify;
        margin: 10px 5% 10px 5%; /* Cambiamos el margen izquierdo a 5% */
    }

    .title, .sub-title {
        font-size: 40px;
        margin-bottom: 20px;
    }
}



</style>