import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "slide-contenedor" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["onDblclick", "onTouchstart"]
const _hoisted_5 = {
  key: 0,
  autoplay: "",
  loop: "",
  controls: ""
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "video-container"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "direcciones" }
const _hoisted_11 = { class: "barras" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([{ active: index === _ctx.currentSlide }, "miSlider"])
      }, [
        (slide.type === 'image')
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: slide.link,
              target: "_blank"
            }, [
              _createElementVNode("img", {
                src: slide.src,
                alt: "Slide Image"
              }, null, 8, _hoisted_3)
            ], 8, _hoisted_2))
          : (slide.type === 'video' && slide.src.endsWith('.mp4'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "video-container",
                onDblclick: ($event: any) => (_ctx.handleVideoClick(slide.link)),
                onTouchstart: ($event: any) => (_ctx.handleVideoClick(slide.link))
              }, [
                (_ctx.currentSlide === index)
                  ? (_openBlock(), _createElementBlock("video", _hoisted_5, [
                      _createElementVNode("source", {
                        src: slide.src,
                        type: "video/mp4"
                      }, null, 8, _hoisted_6),
                      _cache[2] || (_cache[2] = _createTextVNode(" Tu navegador no soporta videos. "))
                    ]))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_4))
            : (slide.type === 'video')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.currentSlide === index)
                    ? (_openBlock(), _createElementBlock("iframe", {
                        key: 0,
                        src: slide.src,
                        frameborder: "0",
                        allow: "autoplay; encrypted-media",
                        allowfullscreen: ""
                      }, null, 8, _hoisted_8))
                    : _createCommentVNode("", true),
                  (_ctx.currentSlide === index)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        onClick: ($event: any) => (_ctx.handleVideoClick(slide.link)),
                        class: "overlay-button"
                      }, " !!! Ver Video Completo !!! ", 8, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
      ], 2))
    }), 128)),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("a", {
        href: "#",
        class: "atras",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.changeSlide(-1)), ["prevent"]))
      }, "❮"),
      _createElementVNode("a", {
        href: "#",
        class: "adelante",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.changeSlide(1)), ["prevent"]))
      }, "❯")
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          class: _normalizeClass(["barra", { active: index === _ctx.currentSlide }]),
          onClick: ($event: any) => (_ctx.setSlide(index))
        }, null, 10, _hoisted_12))
      }), 128))
    ])
  ]))
}