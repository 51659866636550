<template>
  <Slider />
  <InfoSection />
  <SliderAliados />
  <Banner />
  <Popup />
</template>

<script lang="ts">
import Slider from '../components/Slider/Slider.vue';
import InfoSection from '../components/InfoSection/InfoSection.vue';
import SliderAliados from '../components/SliderAliados/SliderAliados.vue';
import Banner from '../components/Banner/Banner.vue';
import Popup from '../components/Popup/Popup.vue';

export default{
  components: {
    Slider,
    InfoSection,
    SliderAliados,
    Banner,
    Popup,
  },
}

</script>

<style scoped>
/* Aquí puedes añadir estilos específicos para HomeView si lo necesitas */
</style>