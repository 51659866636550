<template>
  <Navbar />
  <router-view />
  <SocialLinks />
  <Footer />
</template>

<script lang="ts">
import Navbar from './components/Navbar/Navbar.vue';
import Footer from './components/Footer/Footer.vue';
import SocialLinks from './components/SocialLinks/SocialLinks.vue';

export default {
  components: {
    Navbar,
    SocialLinks,
    Footer,
  }
};
</script>

<style>
/* Estilos globales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Bree Serif", serif;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que el contenido cubra al menos la altura de la pantalla */
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}


</style>
