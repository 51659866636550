import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/imagenes/banner/grafico.png'


const _hoisted_1 = { class: "container-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("h1", { class: "title" }, "Bienvenido a AIKACREE", -1),
    _createElementVNode("img", {
      src: _imports_0,
      alt: "Gráfico descriptivo"
    }, null, -1),
    _createElementVNode("h1", { class: "sub-title" }, "¿Cómo funcionamos?", -1),
    _createElementVNode("p", null, [
      _createTextVNode(" Somos un Operador de Libranzas, un crédito que otorgamos a empleados por medio de un convenio que se firma con la empresa y se descuenta en cómodas cuotas de tu nómina. "),
      _createElementVNode("br"),
      _createElementVNode("br"),
      _createTextVNode(" Tú como empleado autorizas al empleador para descontar de tu salario las cuotas del crédito que AIKACREE te otorga. ")
    ], -1)
  ])))
}