import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "myModal",
  class: "modal"
}
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "video-container" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "close" }, "×", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Bienvenido a AikaCree.", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "¿Quieres solicitar tu crédito? Te enseñamos como !!!.", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showIframe)
          ? (_openBlock(), _createElementBlock("iframe", {
              key: 0,
              src: _ctx.iframeSrc,
              frameborder: "0",
              allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: ""
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}