<template>
    <section class="slider-aliados">
        <h1 class="subtitle">Nuestros Aliados:</h1>
        <div class="slider">
            <div class="slide-track">
                <div class="slide" v-for="(aliado, index) in aliados" :key="index">
                    <img :src="aliado.src" alt="Aliado" />
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" src="./SliderAliados.ts"></script>

<style scoped>
.slider-aliados {
    text-align: center;
    background-color: #9225c5;
    padding: 2% 0;
    color: #fff;
}

.subtitle {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 30px;
}

.slider {
    width: 90vw;
    height: auto;
    margin: auto;
    overflow: hidden;
    background-color: #ffffff;
    padding-top: 1%;
    margin-bottom: 0%;
    border-radius: 25px;
}

.slide-track {
    display: flex;
    animation: scroll 50s linear infinite;
    width: calc(200px * 18); /* Ajustar según la cantidad de aliados */
}

.slide-track::after {
    content: '';
    display: flex;
}

.slide {
    width: 200px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.slide img {
    width: 90%;
    height: auto;
    object-fit: contain;
}

@keyframes scroll {
    0% {
        transform: translate(0);                    
    }
    100% {
        transform: translateX(calc(-200px * 9));
    }
}

</style>