<!-- eslint-disable -->
<template>
    <section class="banner">
        <div class="row">
            <div class="banner-item" v-for="(item, index) in items.slice(0, 3)" :key="index">
                <img :src="item.src" alt="Icono" />
                <p>{{ item.text }}</p>
            </div>
        </div>
        <div class="row">
            <div class="banner-item" v-for="(item, index) in items.slice(3)" :key="index">
                <img :src="item.src" alt="Icono" />
                <p>{{ item.text }}</p>
            </div>
        </div>
    </section>
</template>

<script lang="ts" src="./Banner.ts"></script>

<style scoped>
.banner {
    text-align: center;
    padding: 20px 0;
}

.row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Se ajusta para que las imágenes se reorganicen en pantallas pequeñas */
    margin-bottom: 20px;    
}

.banner-item {
    flex: 1 1 300px; /* Permite que los ítems se ajusten según el tamaño de la pantalla */
    margin: 10 15px;
    max-width: 300px;
    text-align: center;
}

.banner-item img {
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-bottom: 10px;
}

.banner-item p {
    font-size: 25px;
    font-weight: 500;
    color: #4a4a4a;
}
</style>